const developmentEnv = {
    apiUrl: "http://localhost:8080/api",
    signupUrl: "https://cadastro.spdataminhaclinica-staging.com.br",
    urlRedirect: "https://web.spdataminhaclinica-staging.com.br"
}

const builtEnv = {
    // apiUrl: "https://site-api.spdata.com.br/api"
    apiUrl: 'https://site-api.spdata.com.br/api',
    signupUrl: 'https://cadastro.spdataminhaclinica.com.br',
    urlRedirect: 'https://web.spdataminhaclinica.com.br'

}

export const env = process.env.NODE_ENV === "development" ? developmentEnv : builtEnv;
