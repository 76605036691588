<template>
  <section
    id="home-solicitar-atendimento-personalizado"
    class="pad-lg"
    style="padding-bottom: 110px"
  >
    <div class="container">
      <p class="center text-high" data-aos="fade-up">
        <span class="text-lg"
          >Selecione os <b>módulos</b> abaixo e solicite um<b>
            atendimento personalizado com nosso especialista.</b
          ></span
        >
      </p>
      <div class="modulos-content-accordion pad-top-md">
        <!-- PAINEL CLÍNICAS E CONSULTÓRIOS -->
        <button
          type="button"
          id="btn-collapse-clinicas"
          class="btn-collapse collapse-clinicas"
          data-toggle="collapse"
          data-target="#painel-clinicas"
          data-aos="fade-up"
        >
          SPDATA Minha Clínica
        </button>
        <div id="painel-clinicas" class="collapse painel">
          <h4>SPDATA Minha Clínica</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-tele-consulta"
                    value="Tele Consulta"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-tele-consulta"></span>
                <span class="container-text">Tele Consulta</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-agenda-vitual"
                    value="Agenda Virtual Inteligente"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-agenda-virtual"></span>
                <span class="container-text">Agenda Virtual Inteligente</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-recepcao-cadastro"
                    value="Recepção e Cadastro"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-recepcao-cadastro"></span>
                <span class="container-text">Recepção e Cadastro</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-loja-vitual"
                    value="Venda de Produtos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-loja-virtual"> </span>
                <span class="container-text">Venda de Produtos</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-prontuario-eletronico"
                    value="Prontuário Eletrônico"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-prontuario-eletronico">
                </span>
                <span class="container-text">Prontuário Eletrônico</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-controle-financeiro"
                    value="Controle Financeiro"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-controle-financeiro">
                </span>
                <span class="container-text">Controle Financeiro</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-gerenciamento-guias"
                    value="Gerenciamento de Guias - Repasse"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-gerenciamento-guias">
                </span>
                <span class="container-text"
                  >Gerenciamento de Guias - Repasse</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-clinicas-relatorios-gerenciais"
                    value="Relatórios Gerenciais - Dashboard"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img clinicas-relatorios-gerenciais">
                </span>
                <span class="container-text"
                  >Relatórios Gerenciais - Dashboard</span
                >
              </label>
            </div>
          </div>
        </div>

        <!-- PAINEL HOSPITAIS -->
        <button
          type="button"
          id="btn-collapse-hospitais"
          class="btn-collapse collapse-hospitais"
          data-toggle="collapse"
          data-target="#painel-hospitais"
          data-aos="fade-up"
        >
          SPDATA Gestão Hospitalar
        </button>
        <div id="painel-hospitais" class="collapse painel">
          <h4>Controle Assistencial</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-pep"
                    value="PEP - Prontuário Eletrônico do Paciente"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-pep"> </span>
                <span class="container-text"
                  >PEP – Prontuário Eletrônico do Paciente</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-pacs"
                    value="PACS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-pacs"> </span>
                <span class="container-text">PACS</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    id="chk-hospitais-bloco-cirurgico"
                    value="Bloco Cirúrgico"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-bloco-cirurgico"> </span>
                <span class="container-text">Bloco Cirúrgico</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-radiologia"
                    value="Sistema de Radiologia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-radiologia"> </span>
                <span class="container-text">Sistema de Radiologia</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-endoscoia"
                    value="Sistema de Endoscopia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-endoscopia"> </span>
                <span class="container-text">Sistema de Endoscopia</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-anatomia"
                    value="Anatomia Patológica"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-anatomia-patologica">
                </span>
                <span class="container-text"
                  >Sistema de Anatomia Patológica</span
                >
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-ultrassonografia"
                    value="Sistema de Ultrassonografia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-ultrassonografia"> </span>
                <span class="container-text">Sistema de Ultrassonografia</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-tomografia"
                    value="Sistema de Tomografia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-tomografia"> </span>
                <span class="container-text">Sistema de Tomografia</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-hemodinamica"
                    value="Sistema de Hemodinâmica"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-hemodinamica"> </span>
                <span class="container-text">Sistema de Hemodinâmica</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-ressonancia-magnetica"
                    value="Ressonância Magnética"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-ressonancia-magnetica">
                </span>
                <span class="container-text">Ressonância Magnética</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-higienizacao-leitos"
                    value="Controle de Higienização de Leitos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-higienizacao-leitos">
                </span>
                <span class="container-text"
                  >Controle de Higienização de Leitos</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-controle-acesso"
                    value="Controle de Acesso e Visitasntes"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-controle-acesso"> </span>
                <span class="container-text"
                  >Controle de Acesso e Visitantes</span
                >
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-agendamento"
                    value="Sistema de Agendamento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sistema-agendamento">
                </span>
                <span class="container-text">Sistema de Agendamento</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-controle-hemoterapia"
                    value="Controle de Hemoterapia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-hemoterapia"> </span>
                <span class="container-text">Controle de Hemoterapia</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-recepcao-internacao"
                    value="Recepção de Internação"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-recepcao-internacao">
                </span>
                <span class="container-text">Recepção de Internação</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-recepcao-ambulatorial"
                    value="Recepção Ambulatorial de Endoscopia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-recepcao-ambulatorial">
                </span>
                <span class="container-text">Recepção Ambulatorial</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-laboratorio"
                    value="Sistema de Laboratório"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-laboratorio"> </span>
                <span class="container-text">Sistema de Laboratório</span>
              </label>
            </div>
          </div>

          <h4>Controle Administrativo</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-bi"
                    value="SGH Intelligence - BI - Bussiness Intelligence"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sgh-intelligence"> </span>
                <span class="container-text"
                  >SGH Intelligence – BI – Business Intelligence</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-sig"
                    value="SIG - Sistema de Informações Gerenciais"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sig"> </span>
                <span class="container-text"
                  >SIG – Sistema de Informações Gerenciais</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-patrimonio"
                    value="Patrimônio"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-patrimonio"> </span>
                <span class="container-text">Patrimônio</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-tesouraria"
                    value="Tesouraria"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-tesouraria"> </span>
                <span class="container-text">Tesouraria</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-gestao-orcamentaria"
                    value="Gestão Orçamentária"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-orcamentaria">
                </span>
                <span class="container-text">Gestão Orçamentária</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-rpa"
                    value="GRT - Gestão de Repasses e Terceiros"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-rpa"> </span>
                <span class="container-text">GRT - Gestão de Repasses e Terceiros</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-ponto-eletronico"
                    value="Ponto Eletrônico"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-ponto-eletronico"> </span>
                <span class="container-text">Ponto Eletrônico</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-folha-pagamento"
                    value="Folha de Pagamento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-folha-pagamento"> </span>
                <span class="container-text">Folha de Pagamento</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-gestão-financeira"
                    value="Administrativo - Gestão Financeira"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-financeira"> </span>
                <span class="container-text">Gestão Financeira</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-gestão-custos"
                    value="Gestão de Custos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-custos"> </span>
                <span class="container-text">Gestão de Custos</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-sistema-contabilidade"
                    value="Sistema de Contabilidade"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-contabilidade"> </span>
                <span class="container-text">Sistema de Contabilidade</span>
              </label>
            </div>
          </div>

          <h4>Apoio e Faturamento</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-regras-contratuais"
                    value="Gestão de Regras Contratuais - Faturamento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-contratuais">
                </span>
                <span class="container-text"
                  >Gestão de Regras Contratuais – Faturamento</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-faturamento-particulares"
                    value="Faturamento de Particulares"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-faturamento-particulares">
                </span>
                <span class="container-text">Faturamento de Particulares</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-sms"
                    value="Sistema de Envio de SMS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sms"> </span>
                <span class="container-text">Sistema de Envio de SMS</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-sac"
                    value="SAC - Ouvidoria"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sac"> </span>
                <span class="container-text">SAC – Ouvidoria</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-painel-atendimento"
                    value="Painel de Atendimento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-painel-atendimento">
                </span>
                <span class="container-text">Painel de Atendimento</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-gestao-glosas"
                    value="Gestão de Glosas"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-glosas"> </span>
                <span class="container-text">Gestão de Glosas</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-faturamento-convenios"
                    value="Faturamento de Convênios"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-faturamento-convenios">
                </span>
                <span class="container-text">Faturamento de Convênios</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-faturamento-apac"
                    value="Faturamento APAC"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-faturamento-apac"> </span>
                <span class="container-text">Faturamento APAC</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-faturamento-ambulatorial"
                    value="Faturamento Ambulatorial"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-faturamento-ambulatorial">
                </span>
                <span class="container-text"
                  >Faturamento Ambulatorial – SUS</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-aih"
                    value="Faturametno AIH - SUS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-faturamento-aih"> </span>
                <span class="container-text">Faturamento AIH – SUS</span>
              </label>
            </div>
          </div>

          <h4>Controle de Suprimentos</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-almoxarifado"
                    value="Sistema de Almoxarifado"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-sistema-almoxarifado">
                </span>
                <span class="container-text">Sistema de Almoxarifado</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-farmacia"
                    value="Controle de Estoque - Farmácia"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-controle-farmacia"> </span>
                <span class="container-text"
                  >Controle de Estoque – Farmácia</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-hospitais-farmacia"
                    value="Gestão de Compras e Suprimentos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img hospitais-gestao-compra-suprimentos">
                </span>
                <span class="container-text"
                  >Gestão de Compras e Suprimentos</span
                >
              </label>
            </div>
          </div>
        </div>

        <!-- PAINEL OPERADORAS DE PLANOS -->
        <button
          type="button"
          id="btn-collapse-operadoras"
          class="btn-collapse collapse-operadoras"
          data-toggle="collapse"
          data-target="#painel-operadoras"
          data-aos="fade-up"
        >
          SPDATA Gestão de Operadoras
        </button>
        <div class="collapse painel" id="painel-operadoras">
          <h4>Gestão da Operadora</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-empresas-contratantes"
                    value="Gestão da Operadora - Empresas Contratantes / Beneficiário"
                  />
                  <span class="checkmark"></span>
                </div>
                <span
                  class="
                    container-img
                    operadoras-empresas-contratantes-beneficiarios
                  "
                >
                </span>
                <span class="container-text"
                  >Empresas contratantes / Beneficiário</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-comercial-vendas"
                    value="Gestão da Operadora - Comercial e Vendas"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-comercial-e-vendas">
                </span>
                <span class="container-text">Comercial e Vendas</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-produtos-planos"
                    value="Gestão da Operadora - Produtos e Planos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-produtos-planos"> </span>
                <span class="container-text">Produtos e Planos</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-cobrancas"
                    value="Gestão da Operadora - Cobranças"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-cobrancas"> </span>
                <span class="container-text">Cobranças</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-autorizacao-guias"
                    value="Gestão da Operadora - Autorização de Guias"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-autorizacao-guias">
                </span>
                <span class="container-text">Autorização de Guias</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-portal-autorizador"
                    value="Gestão da Operadora - Portal Autorizador"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-portal-autorizador">
                </span>
                <span class="container-text">Portal Autorizador</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-beneficiario-mobile"
                    value="Gestão da Operadora - Beneficiário Mobile"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-beneficiaro-mobile">
                </span>
                <span class="container-text">Beneficiário Mobile</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-atendimento-cliente"
                    value="Gestão da Operadora - Atendimento ao Cliente"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-atendimento-cliente">
                </span>
                <span class="container-text">Atendimento ao Cliente</span>
              </label>
            </div>
          </div>

          <h4>Controle Administrativo</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-sgh"
                    value="SGH Intelligence - Business Intelligence"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-sgh-intelligence"> </span>
                <span class="container-text"
                  >SGH Intelligence - Business Intelligence</span
                >
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-repasse-rpa"
                    value="GRT - Gestão de Repasses e Terceiros"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-repasse-rpa"> </span>
                <span class="container-text">GRT - Gestão de Repasses e Terceiros</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-gestao-financeira"
                    value="Operadoras - Gestão Financeira"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-gestao-financeira">
                </span>
                <span class="container-text">Gestão Financeira</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-relatorios-gerenciais"
                    value="Relatórios Gerenciais"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-relatorios-gerenciais">
                </span>
                <span class="container-text">Relatórios Gerenciais</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-sgh"
                    value="Sistema de Contabilidade"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-sistema-de-contabilidade">
                </span>
                <span class="container-text">Sistema de Contabilidade</span>
              </label>
            </div>
          </div>

          <h4>Cartão Saúde</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-beneficiarios-doadores"
                    value="Beneficiários / Doadores"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-beneficiarios-doadores">
                </span>
                <span class="container-text">Beneficiários / Doadores</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-produtos"
                    value="Produtos"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-produtos"> </span>
                <span class="container-text">Produtos</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-vendas"
                    value="Vendas"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-vendas"> </span>
                <span class="container-text">Vendas</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-cobranca"
                    value="Cobrança"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-cobranca"> </span>
                <span class="container-text">Cobrança</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-autorizacao-de-atendimento"
                    value="Autorização de atendimento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span
                  class="container-img operadoras-autorizacao-de-atendimento"
                >
                </span>
                <span class="container-text">Autorização de atendimento</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-faturamentosaude"
                    value="Faturamento"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-faturamentosaude"> </span>
                <span class="container-text">Faturamento</span>
              </label>
            </div>
          </div>

          <h4>ANS - Agência Nacional de Saúde</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-sib"
                    value="SIB"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-sib"> </span>
                <span class="container-text">SIB</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-sip"
                    value="SIP"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-sip"> </span>
                <span class="container-text">SIP</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-vendas"
                    value="Radar TISS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-radar-tiss"> </span>
                <span class="container-text">Radar TISS</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-tps"
                    value="TPS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-tps"> </span>
                <span class="container-text">TPS</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-monitoramento-TISS"
                    value="Monitoramento TISS"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-monitoramento-tiss">
                </span>
                <span class="container-text">Monitoriamento TISS</span>
              </label>
            </div>
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-rpc"
                    value="RPC"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-rpc"> </span>
                <span class="container-text">RPC</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-operadoras-dados-indicadores"
                    value="Dados e Indicadores"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-dados-indicadores">
                </span>
                <span class="container-text">Dados e Indicadores</span>
              </label>
            </div>
          </div>

          <h4>Faturamento</h4>
          <div class="row">
            <div class="col col-1-6">
              <label class="label-card">
                <div class="box-checkbox">
                  <input
                    type="checkbox"
                    v-model="itens"
                    id="chk-faturamento"
                    value="Contas a Pagar e a Receber"
                  />
                  <span class="checkmark"></span>
                </div>
                <span class="container-img operadoras-faturamento"> </span>
                <span class="container-text">Faturamento</span>
              </label>
            </div>
          </div>
        </div>

        <div class="btn-container center module">
          <a
            href="#"
            class="btn btn-lg btn-pri"
            data-toggle="modal"
            data-target="#formSolicitarAtendimento"
            data-aos="fade-up"
          >
            Solicitar Atendimento
          </a>
        </div>

        <!-- MODAL -->
        <div id="formSolicitarAtendimento" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
                <div class="row group">
                  <div class="col col-2-4">
                    <h5 class="titulo">Solicitar Atendimento Personalizado</h5>
                  </div>
                </div>
                <form
                  :class="this.errors ? 'errors' : false"
                  class="formulario"
                  @submit.prevent="saveAtendimento()"
                >
                  <div class="row group">
                    <div class="col col-2-4">
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Nome Completo *"
                            v-model="nome"
                            required
                            @invalid="invalidateForm"
                          />
                          <span v-show="validations.nome" class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="E-mail *"
                            v-model="email"
                            required
                            @invalid="invalidateForm"
                            pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$"
                          />
                          <span v-show="validations.email" class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Telefone *"
                            v-model="telefone"
                            required
                            @invalid="invalidateForm"
                            maxlength="15"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <span
                            v-show="validations.telefone"
                            class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <textarea
                            placeholder="Mensagem *"
                            v-model="mensagem"
                            required
                            @invalid="invalidateForm"
                          />
                          <span
                            v-show="validations.mensagem"
                            class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col col-2-4">
                      <div class="itens-selecionados">
                        <h5>Itens Selecionados</h5>
                        <div class="itens-selecionados-tags">
                          <span v-for="item in itens" :key="item">{{
                            item
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-1-1">
                      <button class="btn btn-sm btn-pri" type="submit">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SetupApplicationService from "../services/SetupApplicationService.js";

export default {
  data() {
    return {
      itens: [],
      nome: null,
      email: null,
      telefone: null,
      mensagem: null,
      validations: {
        nome: false,
        email: false,
        telefone: false,
        mensagem: false,
      },
      setupApplicationService: new SetupApplicationService(),
      errors: false,
    };
  },
  methods: {
    displaySuccessTooltip(message, type) {
      this.$notify({
        group: 'main',
        text: message,
        type: type
      });
    },

    invalidateForm() {
      this.errors = true;
    },

    saveAtendimento(e) {
      const payload = {
        nome: this.nome,
        email: this.email,
        telefone: this.telefone,
        itens: this.itens,
        mensagem: this.mensagem,
      };

      if (this.checkForm()) {
        this.setupApplicationService.setAtendimento(payload);
        this.nome = '';
        this.email = '';
        this.telefone = '';
        this.mensagem = '';
        this.displaySuccessTooltip('Atendimento solicitado com sucesso!', 'success');
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      if (
        this.nome &&
        this.validEmail(this.email) &&
        this.telefone &&
        this.mensagem
      ) {
        return true;
      }

      this.validations = {};

      this.validations.nome = !this.nome;
      this.validations.telefone = !this.telefone;
      this.validations.mensagem = !this.mensagem;
      this.validations.email = !this.validEmail(this.email);
    },
  },
};
</script>
